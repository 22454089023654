import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
   
	
		
   {
    label: t('Home'),
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: t('Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Exchange'),
        href: '/swap?outputCurrency=0x55d398326f99059ff775485246999027b3197955',
      },
      {
        label: t('Liquidity'),
        href: '/pool',

      },
    ],
  },
 
 
  /* {
    label: t('Farms'),
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: t('Pools'),
    icon: 'PoolIcon',
    href: '/pools',
  }, */
 
  
  {
		label: t('More'),
		icon: 'MoreIcon',

		items: [
				
				
				
				
				{
				label: t('Docs'),
				icon: 'InfoIcon',
				href: 'https://docs.marshmallow.exchange/',
				}, 
				
				 
				
				
				
				
		],},

  
       
 
	
	
	
	
	

]

export default config
