import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [

  {
    sousId: 0,
    stakingToken: tokens.mrsh,
    earningToken: tokens.mrsh,
    contractAddress: {
      97: '0x8ff349ffdc7ddc26233d5e1ea161b98094341acc',
      56: '0x8ff349ffdc7ddc26233d5e1ea161b98094341acc',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '30',
    sortOrder: 1,
    isFinished: false,
  },
  
  
  
]

export default pools
