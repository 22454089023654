import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0,1,2) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'MRSH',
    lpAddresses: {
      97: '0x55d398326f99059ff775485246999027b3197955',
      56: '0x55d398326f99059ff775485246999027b3197955',
    },
    token: tokens.syrup,
    quoteToken: tokens.wbnb,
  },
 
 
  {
    pid: 1,
    lpSymbol: 'MRSH-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x5cab0112de9317b8ce5c882bdd94cd5a341dcc70',
    },
    token: tokens.mrsh,
    quoteToken: tokens.wbnb,
  },
  
  {
    pid: 2,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x5ada794216bedd947db451e6af7c5d923cdb7c5d',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  
   {
    pid: 3,
    lpSymbol: 'MRSH-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x1489ea87ac8ce5482b5f7bca5dc376ae988b3a7d',
    },
    token: tokens.mrsh,
    quoteToken: tokens.wbnb,
  },
  
  {
    pid: 4,
    lpSymbol: 'UST-MRSH LP',
    lpAddresses: {
      97: '',
      56: '0xca54cc1ca16859a664c51e03b7bb2ae28fa20d09',
    },
    token: tokens.ust,
    quoteToken: tokens.mrsh,
  },
  
  
  
  
   
	  
	
// finished last  2  
    

]

export default farms
